<template>
  <div class="cont">
    <a-row :gutter="[48, 48]">
      <a-col :span="4">
        <img style="width:120px;" src="../../../assets/images/blog/huaban1fuben.svg" alt="">
      </a-col>
      <a-col :span="20">
        <a-typography-title :heading="4">
          Industry News and Trends
        </a-typography-title>
        <a-typography-paragraph type="secondary">
          Regularly updated articles or blog posts that cover the latest news, trends, and developments in the logistics
          and supply chain industry. This can include topics like automation, sustainability, emerging technologies,
          regulatory changes, and market insights.
        </a-typography-paragraph>
      </a-col>
    </a-row>
    <a-row :gutter="[48, 48]">
      <a-col :span="4">
        <img style="width:100px;" src="../../../assets/images/blog/zhinan.svg" alt="">
      </a-col>
      <a-col :span="20">
        <a-typography-title :heading="4">
          Guides and Resources
        </a-typography-title>
        <a-typography-paragraph type="secondary">
          Comprehensive guides, e-books, or whitepapers that offer in-depth information and practical advice on various
          logistics topics. These can include guides on optimizing warehouse operations, managing inventory, navigating
          international shipping, understanding logistics terminology, or best practices for transportation management.
        </a-typography-paragraph>
      </a-col>
    </a-row>
    <a-row :gutter="[48, 48]">
      <a-col :span="4">
        <img style="width:80px;" src="../../../assets/images/blog/anlihuicui.svg" alt="">
      </a-col>
      <a-col :span="20">
        <a-typography-title :heading="4">
          Case Studies and Success Stories
        </a-typography-title>
        <a-typography-paragraph type="secondary">
          Real-life examples highlighting successful logistics projects or partnerships. These case studies can
          demonstrate how the company helped clients overcome challenges, improve efficiency, reduce costs, or achieve
          specific goals.
        </a-typography-paragraph>
      </a-col>
    </a-row>
    <a-row :gutter="[48, 48]">
      <a-col :span="4">
        <img style="width:110px;" src="../../../assets/images/blog/shujuzidian.svg" alt="">
      </a-col>
      <a-col :span="20">
        <a-typography-title :heading="4">
          Glossary
        </a-typography-title>
        <a-typography-paragraph type="secondary">
          A comprehensive glossary of logistics terms and acronyms to help visitors understand industry-specific
          terminology. This can be particularly useful for newcomers or individuals unfamiliar with logistics jargon.
        </a-typography-paragraph>
      </a-col>
    </a-row>
    <a-row :gutter="[48, 48]">
      <a-col :span="4">
        <img style="width:80px;" src="../../../assets/images/blog/tips.svg" alt="">
      </a-col>
      <a-col :span="20">
        <a-typography-title :heading="4">
          Best Practices and Tips
        </a-typography-title>
        <a-typography-paragraph type="secondary">
          Practical tips, strategies, and best practices for optimizing logistics operations. This can include advice on
          supply chain management, inventory control, order fulfillment, warehouse layout, freight transportation, and
          sustainability practices.
        </a-typography-paragraph>
      </a-col>
    </a-row>
    <a-row :gutter="[48, 48]">
      <a-col :span="4">
        <img style="width:80px;" src="../../../assets/images/blog/shipinzhibo.svg" alt="">
      </a-col>
      <a-col :span="20">
        <a-typography-title :heading="4">
          Webinars or Video Content
        </a-typography-title>
        <a-typography-paragraph type="secondary">
          Engaging video content or recorded webinars that offer insights, expert interviews, panel discussions, or
          tutorials on relevant logistics topics. This can provide an interactive and visual way for visitors to learn and
          stay updated.
        </a-typography-paragraph>
      </a-col>
    </a-row>
    <a-row :gutter="[48, 48]">
      <a-col :span="4">
        <img style="width:100px;" src="../../../assets/images/blog/fagui.svg" alt="">
      </a-col>
      <a-col :span="20">
        <a-typography-title :heading="4">
          Regulatory and Compliance Information
        </a-typography-title>
        <a-typography-paragraph type="secondary">
          Resources and guides that outline the regulatory requirements, compliance standards, and industry-specific
          regulations that companies need to be aware of when conducting logistics operations.
        </a-typography-paragraph>
      </a-col>
    </a-row>
    <a-row :gutter="[48, 48]">
      <a-col :span="4">
        <img style="width:100px;" src="../../../assets/images/blog/hezuohuoban.svg" alt="">
      </a-col>
      <a-col :span="20">
        <a-typography-title :heading="4">
          Partnerships and Affiliations
        </a-typography-title>
        <a-typography-paragraph type="secondary">
          Information about the company's partnerships, affiliations, certifications, or memberships in industry
          associations, showcasing its commitment to quality, standards, and professional networks.
        </a-typography-paragraph>
      </a-col>
    </a-row>
    <a-row :gutter="[48, 48]">
      <a-col :span="4">
        <img style="width:116px;" src="../../../assets/images/blog/lianjie.svg" alt="">
      </a-col>
      <a-col :span="20">
        <a-typography-title :heading="4">
          Resource Links
        </a-typography-title>
        <a-typography-paragraph type="secondary">
          Curated links to external resources, industry publications, research papers, regulatory bodies, and trade
          associations that provide additional information and insights into the logistics field.
        </a-typography-paragraph>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
.cont {
  width: 1300px;
  margin: 0 auto;
  padding: 140px 40px;
  box-sizing: border-box;
  line-height: 1.8;

  .item {
    display: flex;
    align-items: center;

    img {
      width: 80px;
    }

    .ml {
      margin-left: 40px;
    }
  }
}

.arco-row-justify-start {
  align-items: center;
}

:deep(.arco-row:hover) {
  background-color: #ECFFF1 !important;
  cursor: pointer !important;

  h4.arco-typography {
    cursor: pointer;
    color: rgb(var(--primary-6));
  }
}

.arco-col-4 {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
